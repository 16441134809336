var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loginCont"},[_c('reg-header'),_c('div',{staticClass:"login"},[_c('div',{staticClass:"loginBox"},[_vm._m(0),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm}},[_c('el-form-item',{attrs:{"label":"","rules":[
            { required: true, message: _vm.$t('login_1'), trigger: 'blur' },
            {
              type: 'email',
              message: _vm.$t('login_2'),
              trigger: ['blur'],
            } ],"prop":"account"}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-s-custom","placeholder":_vm.$t('login_3')},model:{value:(_vm.ruleForm.account),callback:function ($$v) {_vm.$set(_vm.ruleForm, "account", $$v)},expression:"ruleForm.account"}})],1),_c('el-form-item',{attrs:{"label":"","prop":"password","rules":[
            { required: true, message: _vm.$t('login_4'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-s-cooperation","placeholder":_vm.$t('login_5'),"show-password":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitLogin.apply(null, arguments)}},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}})],1),_c('el-form-item',{staticClass:"form-item",attrs:{"label":""}},[_c('div',{staticClass:"tipText flex-between"},[_c('div',{staticClass:"reg",on:{"click":function($event){return _vm.toUrl('Email')}}},[_vm._v(" "+_vm._s(_vm.$t('login_6'))+" ")]),_c('div',{staticClass:"forget",on:{"click":function($event){return _vm.toUrl('ForgetPassword')}}},[_vm._v(" "+_vm._s(_vm.$t('login_7'))+" ")])])]),_c('el-form-item',{attrs:{"label":""}},[_c('el-button',{staticClass:"form-login",attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.submitLogin}},[_vm._v(" "+_vm._s(_vm.$t('login_8'))+" ")])],1),_c('el-form-item',{staticClass:"textCenter form-item",attrs:{"label":""}},[_c('el-checkbox',{attrs:{"name":"type"},model:{value:(_vm.isAgreement),callback:function ($$v) {_vm.isAgreement=$$v},expression:"isAgreement"}},[_vm._v(" "+_vm._s(_vm.$t('login_9'))),_c('span',{staticClass:"Agreement",on:{"click":function($event){return _vm.toUrl('PrivacyPolicy')}}},[_vm._v(" "+_vm._s(_vm.$t('login_10'))+" ")])])],1),_c('el-form-item',{staticClass:"textCenter form-item",attrs:{"label":""}},[_c('span',{staticClass:"Agreement",on:{"click":function($event){return _vm.toUrl('Contact')}}},[_vm._v(" "+_vm._s(_vm.$t('login_11'))+" ")])])],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{staticClass:"loginImg",attrs:{"src":require("@/assets/img/login.jpg")}})])}]

export { render, staticRenderFns }