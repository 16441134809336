<template>
  <div class="loginCont">
    <reg-header></reg-header>
    <div class="login">
      <div class="loginBox">
        <div class="logo">
          <img class="loginImg" src="@/assets/img/login.jpg" />
        </div>
        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
          <el-form-item
            label=""
            :rules="[
              { required: true, message: $t('login_1'), trigger: 'blur' },
              {
                type: 'email',
                message: $t('login_2'),
                trigger: ['blur'],
              },
            ]"
            prop="account"
          >
            <el-input
              prefix-icon="el-icon-s-custom"
              v-model="ruleForm.account"
              :placeholder="$t('login_3')"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="password"
            :rules="[
              { required: true, message: $t('login_4'), trigger: 'blur' },
            ]"
          >
            <el-input
              prefix-icon="el-icon-s-cooperation"
              :placeholder="$t('login_5')"
              @keyup.enter.native="submitLogin"
              v-model="ruleForm.password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="" class="form-item">
            <div class="tipText flex-between">
              <div class="reg" @click="toUrl('Email')">
                {{ $t('login_6') }}
              </div>
              <div class="forget" @click="toUrl('ForgetPassword')">
                {{ $t('login_7') }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <el-button
              class="form-login"
              :loading="loading"
              type="primary"
              @click="submitLogin"
            >
              {{ $t('login_8') }}
            </el-button>
          </el-form-item>
          <el-form-item label="" class="textCenter form-item">
            <el-checkbox v-model="isAgreement" name="type">
              {{ $t('login_9')
              }}<span class="Agreement" @click="toUrl('PrivacyPolicy')">
                {{ $t('login_10') }}
              </span>
            </el-checkbox>
          </el-form-item>
          <el-form-item label="" class="textCenter form-item">
            <span class="Agreement" @click="toUrl('Contact')">
              {{ $t('login_11') }}
            </span>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import regHeader from '@/components/base/reg-header.vue'
export default {
  name: 'Login',
  components: {
    regHeader,
  },
  data() {
    return {
      ruleForm: {
        account: '',
        password: '',
      },
      isAgreement: true,
      loading: false,
    }
  },
  mounted() {},
  methods: {
    toUrl(url) {
      this.$router.push({ name: url })
    },
    submitLogin() {
      if (!this.isAgreement) {
        this.$message({
          message: '请先阅读并同意协议',
          type: 'warning',
        })
        return
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let res = await this.$axios.post('/api/User/login', this.ruleForm)
          this.loading = false
          if (res.code === 1) {
            localStorage.setItem('sm_token', res.data.userinfo.token)
            localStorage.setItem('sm_email', res.data.userinfo.email)
            localStorage.setItem(
              'sm_userinfo',
              JSON.stringify(res.data.userinfo)
            )
            this.$router.push({ name: 'Home' })
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.loginCont {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
}
.login {
  height: calc(100% - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBox {
  width: 400px;
  padding: 20px;
  .logo {
    text-align: center;
    .loginImg {
      width: 200px;
    }
  }
}
.tipText {
  .reg {
    color: #409eff;
    cursor: pointer;
  }
  .forget {
    color: #999999;
    cursor: pointer;
  }
}
.form-item {
  &.el-form-item {
    margin-bottom: 0;
  }
  .el-button {
    width: 100%;
  }
}
.form-login {
  width: 100%;
}
.form-item {
  &.el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__content {
    line-height: 20px;
  }
}
.Agreement {
  color: #409eff;
  cursor: pointer;
}
</style>
